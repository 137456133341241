import { theme } from '@ui/style'

interface Icon16ARrowMediumProps {
	color?: string
}
export const Icon16ArrowMedium = ({ color }: Icon16ARrowMediumProps) => {
	const colorValue = color || theme.color.colGray4
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			className="icon-16-arrow"
		>
			<path d="M5.25 2.5L10.75 8L5.25 13.5" stroke={colorValue} strokeWidth="1.5" />
		</svg>
	)
}

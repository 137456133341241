interface IconNotFoundProps {
	isDark?: boolean
}
const IconNotFound = ({ isDark }: IconNotFoundProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
		>
			{isDark ? (
				<>
					<circle cx="18" cy="18" r="18" fill="#444444" />
					<circle cx="18" cy="26.25" r="2.25" fill="#888888" />
					<rect x="16.5" y="7.5" width="3" height="13.5" fill="#888888" />
				</>
			) : (
				<>
					<circle cx="18" cy="18" r="18" fill="#DDDDDD" />
					<circle cx="18" cy="26.25" r="2.25" fill="white" />
					<rect x="16.5" y="7.5" width="3" height="13.5" fill="white" />
				</>
			)}
		</svg>
	)
}

export default IconNotFound

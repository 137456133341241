interface IconNotFoundSmProps {
	isDark?: boolean
}
const IconNotFoundSm = ({ isDark }: IconNotFoundSmProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="29"
			height="28"
			viewBox="0 0 29 28"
			fill="none"
		>
			{isDark ? (
				<>
					<circle cx="14.5" cy="14" r="14" fill="#444444" />
					<circle cx="14.5" cy="20.4167" r="1.75" fill="#888888" />
					<rect x="13.332" y="5.83334" width="2.33333" height="10.5" fill="#888888" />
				</>
			) : (
				<>
					<circle cx="14.5" cy="14" r="14" fill="#DDDDDD" />
					<circle cx="14.5" cy="20.4167" r="1.75" fill="white" />
					<rect x="13.332" y="5.83334" width="2.33333" height="10.5" fill="white" />
				</>
			)}
		</svg>
	)
}

export default IconNotFoundSm

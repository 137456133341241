'use client'

import { ReactNode, forwardRef } from 'react'
import Box, { BoxProps as MuiBoxProps } from '@mui/joy/Box'
import pxToRem from '@utils/pxToRem'

export interface UIContainerProps extends MuiBoxProps {
	children: ReactNode
}
const UIContainer = forwardRef<HTMLDivElement, UIContainerProps>(({ children, ...props }, ref) => {
	return (
		<Box
			margin="0 auto"
			width="100%"
			maxWidth={{
				md: '768px',
				lg: '1024px',
				xl: '1280px',
			}}
			px={{
				xs: pxToRem(20),
				md: pxToRem(24),
				lg: pxToRem(48),
				xl: pxToRem(32),
			}}
			ref={ref}
			{...props}
		>
			{children}
		</Box>
	)
})

export default UIContainer

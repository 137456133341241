'use client'

import UIButton from '@components/ui/UIButton'
import UIContainer from '@components/ui/UIContainer'
import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import { Box } from '@mui/joy'
import color from '@ui/style/color.theme'
import pxToRem from '@utils/pxToRem'
import { notFound } from 'next/navigation'
import nProgress from 'nprogress'
import { Icon16ArrowMedium } from 'src/assets/icons/Icon16ArrowMedium'
import IconNotFound from 'src/assets/icons/IconNotFound'
import { APIResultCode } from 'src/shared/code'
import { MainTenance } from './(errors)/maintenance/MainTenance'
import IconNotFoundSm from 'src/assets/icons/IconNotFoundSm'

export default function Error({
	error,
	reset,
}: {
	error: Error & { digest?: string }
	reset: () => void
}) {
	try {
		const errorObject = JSON.parse(error.message) as CommonResponse<unknown>
		console.log('(일반) errorObject', errorObject)

		if (
			Object.keys(errorObject).length === 0 ||
			errorObject.resultCode === APIResultCode.MAINTENANCE
		) {
			return <MainTenance resultMessage={errorObject.resultMessage} />
		}

		if (
			errorObject.resultCode === APIResultCode.INVALID_URL ||
			errorObject.resultCode === APIResultCode.INVALID_METHOD
		) {
			return notFound()
		}
	} catch (err) {
		console.log('Error occured in error.tsx', err)
	}

	const handleClickHome = () => {
		window.location.href = window.location.origin
	}

	return (
		<html lang="ko">
			<head />
			<body>
				<UIContainer
					sx={{
						'#wrapper:has(&)': {
							'& > header': {
								display: 'none',
								'&.set-sticky': {
									'& + div': {
										margin: 0,
									},
									'& ~ .buttton-go2top': {
										display: 'none',
									},
								},
							},
						},
						'& ~ *': {
							display: 'none',
						},
					}}
				>
					<UIFlex
						align="center"
						justify="center"
						sx={(theme) => ({
							height: '100dvh',
							paddingTop: theme.pxToRem(184),
							paddingBottom: theme.pxToRem(184),
							[theme.breakpoints.down('md')]: {
								paddingTop: theme.pxToRem(144),
								paddingBottom: theme.pxToRem(144),
							},
						})}
					>
						<UIFlex
							align="center"
							justify="center"
							sx={{
								'&>*:not(:first-child)': {
									marginTop: pxToRem(12),
								},
							}}
						>
							<Box
								display={{
									xs: 'none',
									md: 'block',
								}}
								sx={(theme) => ({
									width: theme.pxToRem(36),
									height: theme.pxToRem(36),
								})}
							>
								<IconNotFound />
							</Box>
							<Box
								display={{
									xs: 'block',
									md: 'none',
								}}
								sx={(theme) => ({
									width: theme.pxToRem(28),
									height: theme.pxToRem(28),
								})}
							>
								<IconNotFoundSm />
							</Box>
							<UIText
								color={color.colGray5}
								sx={{
									fontWeight: 600,
									fontSize: {
										xs: pxToRem(20),
										md: pxToRem(22),
										lg: pxToRem(24),
									},
									lineHeight: {
										xs: pxToRem(30),
										md: pxToRem(32),
										lg: pxToRem(34),
									},
								}}
							>
								서비스 연결이 원활하지 않습니다.
							</UIText>
						</UIFlex>
						<UIFlex
							mt={{
								xs: pxToRem(24),
								lg: pxToRem(32),
							}}
							sx={(theme) => ({
								flexDirection: {
									xs: 'column',
									md: 'row',
								},
								'&>*:not(:first-child)': {
									marginLeft: {
										md: theme.pxToRem(16),
									},
									marginTop: {
										xs: pxToRem(12),
										md: 0,
									},
								},
							})}
						>
							<UIButton
								variant="outlined"
								color="neutral"
								sx={(theme) => ({
									'&': {
										width: theme.pxToRem(152),
										height: theme.pxToRem(46),
										paddingTop: 0,
										paddingRight: theme.pxToRem(17),
										paddingBottom: 0,
										paddingLeft: theme.pxToRem(17),
										fontWeight: 600,
										fontSize: theme.pxToRem(16),
										lineHeight: theme.pxToRem(18),
										borderRadius: theme.pxToRem(8),
										color: color.colBlack,
										borderColor: color.colGray3,
									},
									'&:active': {
										background: color.colGray1,
									},
								})}
								onClick={() => {
									nProgress.start()
									window.location.reload()
								}}
							>
								재시도
							</UIButton>
							<UIButton
								variant="outlined"
								color="neutral"
								sx={(theme) => ({
									'&': {
										width: theme.pxToRem(152),
										height: theme.pxToRem(46),
										paddingTop: 0,
										paddingRight: theme.pxToRem(17),
										paddingBottom: 0,
										paddingLeft: theme.pxToRem(17),
										fontWeight: 600,
										fontSize: theme.pxToRem(16),
										lineHeight: theme.pxToRem(18),
										borderRadius: theme.pxToRem(8),
										color: color.colBlack,
										borderColor: color.colGray3,
									},
									'&:active': {
										background: color.colGray1,
									},
									svg: {
										marginLeft: `${pxToRem(4)}!important`,
									},
								})}
								onClick={handleClickHome}
							>
								<UIText whiteSpace="nowrap">JTBC 뉴스 홈</UIText>
								<Icon16ArrowMedium color={color.colBlack} />
							</UIButton>
						</UIFlex>
					</UIFlex>
				</UIContainer>
			</body>
		</html>
	)
}
